@import "~nivo-slider/nivo-slider.css";
@import "../Components/common-product";

.mobile.home {
  #home3_slider {

    .nivo-caption {
      //background: rgba(255 ,255, 255, 0.6);
      overflow: visible;
      bottom: 16%;
      .slide3-text {
        * {
          font-size: 22px !important;
          line-height: 19px !important;
          margin-bottom: 4px !important;
        }

        .cap-view {
          margin-top: 20px !important;
          position: absolute;
          bottom: -40px;
          a {
            font-size: 15px !important;
            padding: 5px 10px;
            border-radius: 0;
          }
        }
      }
    }

    .nivo-directionNav > a {
      height: 30px;
      line-height: 30px;
      width: 30px;
    }
  }

  .home3-banner-area {
    padding-bottom: 20px;

    .home-middle-banners {
      margin: 10px 0;
    }
  }

  @media only screen and (max-width: 480px) and (min-width: 240px) {
    .owl-item {
      > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}
